import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowLeftLong,
    faArrowUpRightFromSquare,
    faArrowsLeftRightToLine,
    faArrowsToLine,
    faBars,
    faBuildingCircleCheck,
    faCameraRetro,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faClipboardListCheck,
    faClock,
    faCloudUpload,
    faCog,
    faComments,
    faCookieBite,
    faCopy,
    faCreditCard,
    faEdit,
    faEnvelope,
    faExternalLink,
    faFaceSmile,
    faFileArrowDown,
    faHeart,
    faHouseLock,
    faInfoCircle,
    faKey,
    faLevelDown,
    faListCheck,
    faLocationDot,
    faMagnifyingGlass,
    faMemoCircleCheck,
    faMinus,
    faMoneyCheckDollar,
    faPenRuler,
    faPencil,
    faPhone,
    faPlus,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faSignIn,
    faTags,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faTruck,
    faTruckFast,
    faUndo,
    faUser,
    faUserHelmetSafety,
    faFilterList as falFilterList,
    faFire as falFire,
    faStar as falStar
} from '@fortawesome/pro-light-svg-icons';

import {
    faBook,
    faCommentQuestion,
    faSpinnerThird,
    faCircleDot as fadCircleDot,
    faCircleHalf as fadCircleHalf,
    faComments as fadComments,
    faFloppyDisk as fadFloppyDisk,
    faStarHalf as fadStarHalf,
    faUser as fadUser,
} from '@fortawesome/pro-duotone-svg-icons';

// import { faCommentQuestion } from '@fortawesome/duotone-regular-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faLinkedin, faPinterest, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faCirclePlay,
    faExclamationTriangle,
    faFloppyDisk,
    faMinusCircle,
    faPlusCircle,
    faShieldBlank,
    faSignOut,
    faTable,
    faUsers,
    faAngleDown as fasAngleDown,
    faCircleCheck as fasCheckCircle,
    faCircle as fasCircle,
    faClipboardListCheck as fasClipboardListCheck,
    faCloudUpload as fasCloudUpload,
    faComments as fasComments,
    faCopy as fasCopy,
    faDoorClosed as fasDoorClosed,
    faDoorOpen as fasDoorOpen,
    faEnvelope as fasEnvelope,
    faFileSpreadsheet as fasFileSpreadsheet,
    faFilterList as fasFilterList,
    faGift as fasGift,
    faInfoCircle as fasInfoCircle,
    faKey as fasKey,
    faMedal as fasMedal,
    faPhone as fasPhone,
    faShieldCheck as fasShieldCheck,
    faStar as fasStar,
    faStarHalf as fasStarHalf,
    faTags as fasTags,
    faTruck as fasTruck,
    faUser as fasUser,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faUser,
    fasUser,
    fadUser,
    faBars,
    faTimes,
    faShoppingCart,
    faSearch,
    faCheck,
    fasStar,
    fasStarHalf,
    fadStarHalf,
    fasCircle,
    faAngleDown,
    faAngleUp,
    faCheckCircle,
    faThumbsUp,
    faFacebook,
    faInstagram,
    faPlus,
    faChevronRight,
    faChevronLeft,
    fadCircleDot,
    fasInfoCircle,
    faInfoCircle,
    faTags,
    fasTags,
    faCopy,
    fasCopy,
    faClock,
    faKey,
    fasKey,
    faMinus,
    fasCloudUpload,
    faTrashAlt,
    faCog,
    faLevelDown,
    faSpinnerThird,
    faExclamationTriangle,
    faPencil,
    faEdit,
    faUndo,
    faPlusCircle,
    faMinusCircle,
    faShieldBlank,
    faTruck,
    faTruckFast,
    faUserHelmetSafety,
    faMemoCircleCheck,
    faPenRuler,
    fasMedal,
    faAngleRight,
    faHeart,
    faCirclePlay,
    faArrowLeftLong,
    fasCheckCircle,
    faAngleLeft,
    fasGift,
    faCreditCard,
    faQuestionCircle,
    faPhone,
    faComments,
    faMoneyCheckDollar,
    fadCircleHalf,
    fasAngleDown,
    fasFilterList,
    faUsers,
    fasShieldCheck,
    fasFileSpreadsheet,
    fasDoorOpen,
    fasDoorClosed,
    faFloppyDisk,
    fadFloppyDisk,
    faTable,
    faFileArrowDown,
    faListCheck,
    faMagnifyingGlass,
    faSignIn,
    falFilterList,
    faWhatsapp,
    faLocationDot,
    faBuildingCircleCheck,
    faHouseLock,
    faFaceSmile,
    faCloudUpload,
    faPinterest,
    faYoutube,
    faEnvelope,
    falStar,
    faCameraRetro,
    faArrowUpRightFromSquare,
    faSignOut,
    faExternalLink,
    faLinkedin,
    fasComments,
    fasEnvelope,
    fasPhone,
    faCookieBite,
    faClipboardListCheck,
    fasClipboardListCheck,
    fadComments,
    faArrowsLeftRightToLine,
    faArrowsToLine,
    fasTruck,
    falFire,
    faCommentQuestion,
    faBook
);
