<template>
    <div class="flex-1 md:flex items-center px-6 lg:justify-left">
        <div class="w-full md:w-1/4">
            <img class="block h-7 w-auto mb-4 md:mb-0" :src="asset('img/doorax-logo.svg')" width="158" height="28" alt="Doorax" />
        </div>
        <div class="w-full md:w-3/4">
            <div class="container relative mx-auto flex flex-wrap usps text-sm md:pl-auto space-y-2 md:space-y-0">
                <div class="md:flex-auto w-full md:w-auto">
                    <a
                        target="_blank"
                        class="flex text-center hover:text-gray-800"
                        :href="url('klantenservice', 'levertijd-verzendkosten')"
                    >
                        <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg inline-block w-4 h-4" />

                        <span
                            >Voor <strong>23:59</strong> besteld, morgen <strong class="lg:hidden">gratis</strong> in
                            huis<span class="">&#x2a;</span></span
                        >
                    </a>
                </div>

                <div class="flex flex-auto items-center w-full md:w-auto">
                    <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg inline-block w-4 h-4" />

                    <span><strong>Gratis</strong> verzending</span>
                </div>

                <div class="flex flex-auto items-center w-full md:w-auto">
                    <a target="_blank" class="hover:text-gray-800" :href="url('klantenservice', 'retourneren')">
                        <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg inline-block w-4 h-4" />

                        <strong>30 dagen</strong> bedenktijd<span class="">&#x2a;</span>
                    </a>
                </div>

                <div class="lg:flex flex-auto hidden items-center w-full md:w-auto">
                    <!-- <fa :icon="['fal', 'check']" class="text-gray-100 mr-2 w-3 inline-block" />  -->
                    <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg inline-block w-4 h-4" />
                    <span><strong>Deskundig</strong> advies</span>
                </div>
            </div>
        </div>
    </div>
</template>
