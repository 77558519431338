<template>
    <Listbox as="div" v-model="selected" class="w-full">
        <slot></slot>
        <div class="relative pb-4">
            <ListboxButton
                class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-4 pr-10 text-left cursor-pointer focus:outline-none hover:shadow-md transition-shadow flex items-center"
                :class="categoryFilter ? 'py-3' : 'py-4'"
            >
                <fa :icon="['fal', 'key']" class="h-5 w-5 text-gray-400 inline-block mr-4" />
                <span v-if="keysSelected" class="inline-block truncate">
                    {{ selected }} sleutels<span v-if="selected == default_qty" class="pl-2">(standaard)</span>
                </span>
                <span v-else class="inline-block truncate">Aantal sleutels in set</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <fa :icon="['fal', 'angle-down']" class="h-4 w-4 text-gray-400" />
                </span>
            </ListboxButton>

            <ListboxOptions
                class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-[380px] md:max-h-screen rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
            >
                <ListboxOption as="template" v-for="n in 18" :key="n" :value="n" v-slot="{ active, selected }">
                    <div>
                        <li
                            :class="[
                                selected || active ? 'bg-gray-100' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-4 pr-9',
                            ]"
                            @click="
                                $emit('update:keyQty', n);
                                keysSelected = true;
                            "
                            v-if="show(n)"
                        >
                            <div
                                :class="[selected ? 'font-semibold' : 'font-normal', 'block']"
                                class="flex items-center"
                            >
                                <span class="inline-block">{{ n }} sleutels</span>
                                <span v-if="n == default_qty" class="pl-2">(standaard)</span>
                            </div>

                            <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
                                <fa :icon="['fal', 'check']" class="h-5 w-5 text-gray-400" />
                            </span>
                        </li>
                    </div>
                </ListboxOption>
            </ListboxOptions>
        </div>
    </Listbox>
</template>

<script setup>
import { onMounted, ref } from 'vue';

let props = defineProps({
    default_qty: Number,
    keyQty: Number | Boolean,
    keysSelected: Boolean,
    categoryFilter: Boolean,
});

const selected = ref(props.keyQty);
const keysSelected = ref(props.keysSelected);

onMounted(function(){
    setTimeout(() => {
        selected.value = props.keyQty
        keysSelected.value = true;
    }, 200);
})

function show(qty) {
    return qty >= props.default_qty;
}
</script>
