<template>
    <footer
        class="bg-white shadow-invert"
        aria-labelledby="footerHeading"
    >
        <span id="footerHeading" class="sr-only">Footer</span>
        <div class="w-full border-b">
            <div class="max-w-screen-2xl mx-auto py-4">
                <UspsFooter />
            </div>
        </div>

        <div class="bg-white">
            <div class="max-w-screen-2xl mx-auto py-8 px-6">
                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/4 mb-4 md:mb-0">
                        <h3 class="md:text-sm font-semibold text-dblue tracking-wider uppercase mb-6">Doorax</h3>
                        <ul class="">
                            <li v-for="link in doorax">
                                <Link :href="link.url" :title="link.title" class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 inline-block w-full">
                                    {{ link.title }}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div class="w-full md:w-1/4 mb-4 md:mb-0">
                        <h3 class="md:text-sm font-semibold text-dblue tracking-wider uppercase mb-6">Persoonlijk advies</h3>
                        <div class="flex my-4" v-for="channel in channels">
                            <div class="flex items-center w-48">
                                <div class="w-8">
                                    <fa :icon="[channel.iconType, channel.icon]" class="inline-block text-base text-dgreen mr-3" :class="channel.class" />
                                </div>

                                <a :href="channel.url" :target="channel.icon == 'envelope' ? '_blank' : ''" class="">
                                    <strong class="block font-bold">{{ channel.title }}</strong>
                                </a>
                            </div>
                            <span class="text-gray-600 text-sm font-normal">{{ channel.response }}<span class="text-xs -mt-1 inline-block">*</span></span>
                        </div>
                    </div>

                    <div class="w-full md:w-1/4 mb-4 md:mb-0">
                        <h3 class="md:text-sm font-semibold text-dblue tracking-wider uppercase mb-6">Volg ons</h3>
                        <ul class="flex space-x-4 my-4">
                            <li v-for="social in socials">
                                <a :href="social.url" target="_blank" :title="social.title">
                                    <fa :icon="['fab', social.icon]" class="w-6 h-6 text-dgreen hover:text-gray-600 transition-all" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="w-full md:w-1/4 mb-4 md:mb-0">
                        <div>
                            <h3 class="md:text-sm font-semibold text-dblue tracking-wider uppercase mb-6">Nieuwsbrief</h3>
                            <p class="mb-4 text-gray-500">Ontvang exclusieve acties en kortingen.</p>
                        </div>
                        <form class="mt-4 md:flex md:max-w-md lg:mt-0" @submit.prevent="submit">
                            <label for="emailAddress" class="sr-only">E-mailadres</label>
                            <input
                                type="email"
                                name="emailAddress"
                                id="emailAddress"
                                v-model="form.email"
                                autocomplete="email"
                                required=""
                                class="text-input"
                                placeholder="Uw e-mailadres"
                            />
                            <div class="mt-3 rounded-md md:mt-0 md:ml-3 md:flex-shrink-0">
                                <button type="submit" class="button dgreen">
                                    <fa :icon="['fad', 'spinner-third']" class="text-white opacity-80 mr-2 fa-spin" v-if="form.processing" />
                                    Inschrijven
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-gray-50 md:text-sm border-t">
            <div class="max-w-screen-2xl mx-auto py-8 px-6">
                <div class="md:grid md:grid-cols-4 md:gap-8 pb-8">
                    <div>
                        <h3 class="md:text-sm font-semibold text-dblue h-6 tracking-wider uppercase">Klantenservice</h3>
                        <ul class="mt-4">
                            <li v-for="item in footerLinks.klantenservice">
                                <Link :href="'/klantenservice/' + item.slug" class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block">{{
                                    item.title
                                }}</Link>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 md:mt-0">
                        <h3 class="md:text-sm font-semibold text-dblue h-6 tracking-wider uppercase">Advies</h3>
                        <ul class="mt-4">
                            <li v-for="item in footerLinks.advice">
                                <Link :href="url('advies/' + item.slug)" :title="item.title" class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block">{{
                                    item.title
                                }}</Link>
                            </li>
                            <li>
                                <Link :href="url('advies')" title="Lees al onze adviezen" class="link py-1.5 md:py-1 w-full inline-block"
                                    >Lees al onze adviezen</Link
                                >
                            </li>
                        </ul>
                    </div>

                    <div class="mt-12 md:mt-0 relative">
                        <h3 class="md:text-sm font-semibold text-dblue h-6 tracking-wider uppercase">Toepassingen</h3>
                        <ul class="mt-4">
                            <li v-for="item in footerLinks.toepassingen">
                                <Link
                                    :href="url('cilindersloten/' + item.toLowerCase())"
                                    :title="item"
                                    class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block"
                                >
                                    {{ item }}
                                </Link>
                            </li>
                            <li v-for="item in footerLinks.overig">
                                <Link
                                    :href="'/cilindersloten/' + item.url"
                                    :title="item.title"
                                    class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block"
                                >
                                    {{ item.title }}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="url('cilindersloten/sets')"
                                    title="1 t/m 7 cilinders in set"
                                    class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block"
                                >
                                    1 t/m 7 cilinders in set
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 md:mt-0 relative">
                        <h3 class="md:text-sm font-semibold text-dblue tracking-wider uppercase">Cilinder Maten</h3>
                        <ul class="mt-4">
                            <li v-for="item in [60, 65, 70, 75, 80, 85, 90, 95, 100]">
                                <Link
                                    :href="'/cilindersloten/maten/' + item + 'mm'"
                                    :title="item + 'mm'"
                                    class="text-gray-500 hover:text-gray-900 py-1.5 md:py-1 w-full inline-block"
                                >
                                    {{ item }}mm
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
                    <p class="mt-8 text-gray-400 md:mt-0">
                        <a href="/privacy" title="Privacy &amp; Cookies" class="border-r pr-3 border-gray-200">Privacy</a>
                        <a href="/algemene-voorwaarden" title="Algemene voorwaarden" class="border-r px-3 border-gray-200">Voorwaarden</a>
                        <span class="px-3">© 2025 Doorax</span>
                    </p>
                    <div class="md:pr-8 text-center py-3 md:py-0">
                        <span class="sr-only">Keurmerken</span>
                        <a target="_blank" href="https://www.webwinkelkeur.nl/leden/Doorax_1202649.html" title="Keurmerk webwinkel" class="inline-block mr-4" loading="lazy">
                            <img :src="asset('img/keurmerk.png')" width="60" height="40" alt="Keurmerk webwinkel" />
                        </a>
                        <a target="_blank" href="https://www.politiekeurmerk.nl/" title="Politiekeurmerk Veilig Wonen" class="inline-block" loading="lazy">
                            <img :src="asset('img/PKVW-Doorax.png')" width="27" height="48" alt="Politiekeurmerk Veilig Wonen" />
                        </a>
                    </div>
                    <div class="px-4">
                        <span class="sr-only">Betaalmethodes</span>
                        <img :src="asset('img/betaalmethodes.png')" alt="Veilig betalen met onze betaalmethodes" class="md:h-8" width="483" height="32" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import UspsFooter from '../Blocks/UspsFooter.vue';

const footerLinks = ref({
    klantenservice: [
        {
            title: 'Levering & verzending',
            slug: 'levertijd-verzendkosten',
        },
        {
            title: 'Retourneren',
            slug: 'retourneren',
        },
        {
            title: 'Betalen',
            slug: 'betaalmethodes',
        },
        {
            title: 'Garantie & klachten',
            slug: 'garantie-klachten',
        },
        {
            title: 'Veelgestelde vragen',
            slug: 'veelgestelde-vragen',
        },
        {
            title: 'Contact & gegevens',
            slug: 'contact',
        },
        {
            title: 'Klantenservice',
            slug: 'klantenservice',
        },
        {
            title: 'Bestellen',
            slug: 'bestellen',
        },
        {
            title: 'Zakelijk',
            slug: 'zakelijk',
        },
    ],
    advice: [
        {
            title: 'Cilinderslot uitboren',
            slug: 'cilinderslot-uitboren',
        },
        {
            title: 'Cilinderslot verwijderen',
            slug: 'cilinderslot-verwijderen',
        },
        {
            title: 'Cilinderslot draait door',
            slug: 'cilinderslot-draait-door',
        },
        {
            title: 'Opmeten van een cilinderslot',
            slug: 'cilinderslot-opmeten',
        },
        {
            title: 'Cilinderslot zit vast',
            slug: 'cilinderslot-zit-vast',
        },
        {
            title: 'Cilinderslot smeren',
            slug: 'cilinderslot-smeren',
        },
        {
            title: 'Hoe werkt een cilinderslot?',
            slug: 'hoe-werkt-een-cilinderslot',
        },
        {
            title: 'Cilinderslot vervangen?',
            slug: 'hoe-vervang-je-een-cilinderslot',
        },
        {
            title: 'Cilinderslot schoonmaken',
            slug: 'cilinderslot-schoonmaken',
        },
        {
            title: 'Cilinderslot bevroren',
            slug: 'cilinderslot-bevroren',
        },
        {
            title: 'Kerntrekken',
            slug: 'wat-is-kerntrekken',
        },
    ],
    toepassingen: ['Voordeur', 'Buitendeur', 'Poort', 'Garagedeur', 'Tuindeur'],
    overig: {
        1: {
            title: 'Met certificaat',
            url: 'certificaat',
        },
        2: {
            title: 'Gelijksluitend',
            url: 'gelijksluitend',
        },
        3: {
            title: 'SKG3 gekeurd',
            url: 'skg3',
        },
    },
});

// onMounted(() => {
//     axios
//         .get('/api/footer-data')
//         .then((response) => {
//             footerLinks.value = response.data;
//         })
//         .catch((error) => {});
// });

const socials = [
    {
        url: 'https://www.instagram.com/doorax_nl/',
        title: 'Instagram',
        icon: 'instagram',
    },
    {
        url: 'https://www.facebook.com/people/Doorax/100063985006518/',
        title: 'Facebook',
        icon: 'facebook',
    },
    {
        url: 'https://nl.pinterest.com/DooraxNederland/',
        title: 'Pinterest',
        icon: 'pinterest',
    },
    {
        url: 'https://www.youtube.com/@doorax',
        title: 'Youtube',
        icon: 'youtube',
    },
];

const doorax = [
    {
        url: '/over-ons',
        title: 'Over ons',
    },
    {
        url: '/klantenservice/zakelijk',
        title: 'Zakelijk',
    },
    {
        url: '/offerte',
        title: 'Offerte aanvragen',
    },
    {
        url: '/vacatures',
        title: 'Vacatures',
    },
];

const channels = [
    {
        title: 'Live chat',
        response: 'Direct contact',
        url: "javascript:void(window.mitt.emit('open-chat'))",
        icon: 'comments',
        iconType: 'fas',
    },
    {
        title: 'info@doorax.nl',
        response: 'Reactie binnen 12uur',
        url: 'mailto:info@doorax.nl',
        icon: 'envelope',
        iconType: 'fas',
    },
    {
        title: '026-2340303',
        response: 'Direct contact',
        url: 'tel:+31262340303',
        icon: 'phone',
        iconType: 'fas',
    },
];

const form = useForm({
    email: null,
});

function submit() {
    form.post('/subscribe', {
        preserveScroll: true,
    });
}
</script>
