<template>
    <div class="relative text-gray-800">
        <strong class="px-6">Keuzehulp cilinders</strong>
        <!-- <fa :icon="['fas', 'play']" class="absolute right-6 top-2 text-gray-200 fa-3x" /> -->

        <div class="">
            <span class="px-6 my-1 block">In 4 stappen de juiste cilinders</span>
            <button class="relative w-full group cursor-pointer" @click="showWizard">
                <div class="px-3 pt-4">
                    <!-- <VuePicture :width="272" :height="230"  :image="{src:'img/wizard-intro.webp',origin_src:'img/wizard-intro.png'}" name="Cilinderset" :style="'rounded-lg shadow-overallxl'"/> -->
                    <!-- <VuePicture :width="272" :height="230"  :image="{src:'img/wizard-intro.webp',origin_src:'img/wizard-intro.png'}" name="Cilinderset" :style="'rounded-lg shadow-overallxl group-hover:shadow-overallxxl transition-all group-hover:scale-105'"/> -->
                    <VuePicture  :image="{src:asset('img/wizard-small.webp'),origin_src:asset('img/wizard-small.png')}" name="Cilinderset" :style="'transition-all group-hover:scale-105'" :width="344" :height="145"/>
                </div>
                <div class="flex flex-wrap absolute inset-0">
                    <div class="h-1/4 w-full group-hover:h-1/6 transition-all"></div>
                    <div
                        class="h-1/2 w-full bg-gradient-to-b from-transparent to-white group-hover:h-4/6 transition-all"
                    ></div>
                    <div class="h-1/4 w-full bg-white group-hover:h-1/6 transition-all"></div>
                </div>
            </button>
        </div>
        <button @click="showWizard" class="button dgreen mx-6 -mt-24 relative">
            Start keuzehulp
            <fa :icon="['fas', 'circle-play']" class="ml-2" />
        </button>
    </div>
</template>

<script>
const inBrowser = typeof window !== 'undefined';

export default {
    setup(context) {
        function showWizard() {
            if(inBrowser){
                window.mitt.emit('close-slideover', true);
                window.mitt.emit('open-wizard', true);
            }
            context.emit('showWizard');
        }

        return {
            showWizard,
        };
    },
};
</script>
