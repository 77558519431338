import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import config from './cookieconsent-config2';
const inBrowser = typeof window !== 'undefined';

// Function to detect ad blocker
const detectAdBlocker = () => {
    return new Promise((resolve) => {
        document.addEventListener('DOMContentLoaded', () => {
            const adDiv = document.createElement('div');
            adDiv.id = 'ad_banner'; // Commonly blocked ID
            adDiv.style.height = '1px';
            adDiv.style.width = '1px';
            adDiv.style.position = 'absolute';
            adDiv.style.top = '-9999px';

            document.body.appendChild(adDiv);

            if (window.getComputedStyle(adDiv).display === 'none') {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

export default {
    install: async (app) => {
        if (inBrowser) {
            // Check for ad blocker
            const hasAdBlocker = await detectAdBlocker();

            if (hasAdBlocker) {
                setTimeout(() => {
                    CookieConsent.hide();
                }, 10);
            }

            // Run cookie consent
            CookieConsent.run(config);
            window.$CookieConsent = CookieConsent;
            app.config.globalProperties.$CookieConsent = CookieConsent;

            // Add ad blocker status to global properties
            app.config.globalProperties.$hasAdBlocker = hasAdBlocker;
            window.$hasAdBlocker = hasAdBlocker;
        }
    },
};
