<template>
    <Menu as="div" class="relative" v-slot="{ open }">
        <MenuButton
            :class="{ 'bg-gray-100': open }"
            class="px-3 py-2 rounded-md flex text-sm focus:outline-none hover:bg-gray-100"
        >
            <span class="sr-only">Mijn account</span>
            <fa :icon="['fal', 'user']" class="text-xl h-5 opacity-80" />
            <!-- <span
                class="ml-2 hidden md:block"
                v-if="$page.props.user"
                >{{ $page.props.user.first_name }}</span
            > -->
        </MenuButton>

        <transition name="drop">
            <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <MenuItem v-slot="{ active }">
                    <Link
                        :href="url('dashboard')"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                        >Mijn account</Link
                    >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <Link
                        :href="url('account/orders')"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                        >Bestellingen</Link
                    >
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="$page.props.user">

                        <button
                            href="#"
                            @click="logout()"
                            :class="[
                                active ? 'bg-gray-100' : '',
                                'block w-full text-left px-4 py-2 text-sm text-gray-700',
                            ]"
                        >
                            Uitloggen
                        </button>
               
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import { ref } from 'vue';
const inBrowser = typeof window !== 'undefined';

export default {

    data(){
        return {
            // csrf: inBrowser ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : false
        }
    },
    methods: {
        logout() {
            this.$inertia.post('/logout', {
                onSuccess() {
                    // location.reload();
                },
            });
        },
    },
};
</script>
