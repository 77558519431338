<template>
    <navbar />

    <main v-if="withoutContainer">
        <slot></slot>
    </main>
    <main v-else class="max-w-screen-2xl container mx-auto px-4 pt-4 lg:px-8 pb-16 flex-grow md:pt-8">
        <slot></slot>
    </main>

    <AppFooter />

    <FlashMessages v-if="flash" />

    <Wizard v-if="showWizard" />

    <Comparison v-if="comparisonList" />

    <MobileChat />

    <Transition name="fade">
        <div id="trengo-widget-fake" class="hidden-mobile" v-if="loaded && !chatOpened" @click="loadChat">
            <div class="fixed bottom-6 right-4 z-50">
                <div
                    class="hidden bg-dgreen rounded-2xl absolute right-20 px-4 py-2 w-44 pl-4 text-white font-semibold bottom-3 md:flex items-center cursor-pointer"
                    style="font-size: 14px !important"
                >
                    <fa :icon="['fas', 'circle']" class="text-xs h-3 mr-2 text-green-400 rounded-full p-0.5" />
                    <span>Stel hier je vraag</span>
                </div>
                <div
                    class="h-16 w-16 bg-dgreen opacity-100 group transform transition-scale ease-in-out duration-200 px-4 p-2 rounded-3xl shadow-overallxl cursor-pointer z-50 flex items-center justify-center"
                >
                    <fa :icon="['fad', 'comments']" v-if="!showChat" class="text-white w-10 h-10 mx-auto group-hover:scale-110 transition-all" />
                    <Transition name="fade">
                        <fa :icon="['fad', 'spinner-third']" class="text-white w-7 h-7 mx-auto mt-1.5 ml-0.5 fa-spin" v-if="showChat" />
                    </Transition>

                    <!-- <div class="launcher-triangle" style="border-top-color: #07589f"></div> -->
                </div>
            </div>
        </div>
    </Transition>

    <Trengo v-if="showChat == true" />

    <div
        class="bg-orange-100 border-orange-500 bg-yellow-100 border-yellow-500 bg-red-100 border-red-500 bg-blue-100 border-blue-500 bg-blue-100 border-purple-600 h-5 w-5 border-gray-300 rounded text-blue-600 transition focus:ring-0 hover:border-gray-600 grid grid-cols-12 col-span-2 hover:border-gray-400"
    ></div>
</template>

<script setup>
import MobileChat from '@/Blocks/MobileChat.vue';
import AppFooter from '@/Layouts/Footer.vue';
import Navbar from '@/Layouts/Navbar.vue';
import { usePage } from '@inertiajs/vue3';
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue';

const inBrowser = typeof window !== 'undefined';

// Define async component for AppFooter

const showCookie = ref(false);
const showWizard = ref(false);
const showChat = ref(false);
const loaded = ref(false);
const loadFooter = ref(false);

const Comparison = defineAsyncComponent(() => import('@/Components/ComparisonList.vue'));
const FlashMessages = defineAsyncComponent(() => import('@/Components/FlashMessages.vue'));
const Wizard = defineAsyncComponent(() => import('@/Components/Wizard.vue'));
const Trengo = defineAsyncComponent(() => import('@/Plugins/Trengo.vue'));

// Page props
const comparisonList = computed(() => usePage().props.comparisonList);
const flash = usePage().props.flash;
const chatOpened = usePage().props.chat_opened;

const mobileChatOpened = ref(false);

defineProps({
    withoutContainer: Boolean,
});

onMounted(() => {
    window.mitt.on('open-chat', loadChat);
    window.mitt.on('mobile-chat-opened', flagChatOpened);
    loaded.value = true;
});

function flagChatOpened() {
    mobileChatOpened.value = true;
}

function loadChat() {
    // console.log(chatOpened);
    // console.log(mobileChatOpened.value);

    if (chatOpened || mobileChatOpened.value) {
        window.Trengo.Api.Widget.open('chat');
    } else {
        showChat.value = true;
        // send axios request to open chat
        axios.get('/api/chat/opened');
    }
}

onUnmounted(() => window.mitt.off('open-chat', loadChat));

function handleScroll() {
    if (window.scrollY > 100 || !inBrowser) {
        loadFooter.value = true;
    }
}

if (inBrowser) {
    window.addEventListener('scroll', handleScroll);

    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        loadFooter.value = true;
    }

    document.addEventListener('keypress', (event) => {
        var name = event.key;
        if (name == 'c') {
            showCookie.value = true;
        }
    });

    window.mitt.on('open-wizard', (evt) => {
        showWizard.value = true;

        setTimeout(() => {
            let chat = document.getElementsByClassName('TrengoOnlineStatus__iframe');
            if (chat && chat.length) {
                chat[0].style.display = 'none';
            }
        }, 100);

        if ('ontouchstart' in document.documentElement) {
            let chat = document.getElementsByClassName('TrengoWidgetLauncher__iframe');

            if (chat && chat.length) {
                chat[0].style.bottom = '80px';
            }

            document.body.style.position = 'fixed';
            document.body.style.overflow = 'hidden';
        }
    });
}
</script>

<style scoped>
.launcher-triangle,
.launcher-triangle-left {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 34px solid transparent;
    border-bottom: 0;
    margin-bottom: -8px;
    margin-right: 4px;
    z-index: -1;
}

.launcher-triangle {
    margin-left: -7px;
    transform: rotate(90deg);
}
</style>
