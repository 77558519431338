import { ref } from 'vue';

const isMobile = ref(typeof window !== 'undefined' ? window.innerWidth < 768 : false);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth < 768;
};

if (typeof window !== 'undefined') {
    window.addEventListener('resize', updateIsMobile);
}

export default {
    isMobile
}; 